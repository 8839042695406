/* @flow */

import { StripeSession } from "../../app/models/User";

export default async function createCheckoutSession(
  priceId: string,
  returnUrl: string,
  subscriptionId: string | null
): Promise<StripeSession> {
  return new Promise(async (resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        priceId: priceId,
        successUrl: returnUrl,
        cancelUrl: `${process.env.REACT_APP_PUBLIC_URL}/cancel`,
        subscriptionId: subscriptionId ?? null,
      }),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data && data.url) {
          const stripeSession: StripeSession = data;
          resolve(stripeSession);
        } else {
          reject(new Error("Failed to create checkout session"));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
