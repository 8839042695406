import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import { Tooltip } from "bootstrap";

import { CompanyAppStatus } from "../../../helpers/Enums";
import { App } from "../../models/App";
import { CompanyApp } from "../../models/CompanyApp";
import i18n from "../../../i18n";

import stores from "../../stores";

interface DepartmentSelectionTableProps {
  selectedDepartmentId: number | null;
  onDepartmentToggle: (departmentId: number | null) => void;
  departmentMap: {
    [key: number]: { name: string; description?: string; apps: App[] };
  };
}

const DepartmentSelectionTable: React.FC<DepartmentSelectionTableProps> =
  observer(({ selectedDepartmentId, onDepartmentToggle, departmentMap }) => {
    const { companyApps } = stores.companyAppStore;

    const tableRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const tooltipTriggerList = document.querySelectorAll(
        '[data-bs-toggle="tooltip"]'
      );
      const tooltipList = Array.from(tooltipTriggerList).map(
        (tooltipTriggerEl) => new Tooltip(tooltipTriggerEl)
      );

      return () => {
        tooltipList.forEach((tooltip) => tooltip.dispose());
      };
    }, [departmentMap]);

    const isAppConnected = (appId: number) => {
      return companyApps.some(
        (companyApp: CompanyApp) =>
          companyApp.app_id.toString() === appId.toString() &&
          companyApp.status === CompanyAppStatus.Connected
      );
    };

    const getAppOpacity = (appId: number) => {
      return isAppConnected(appId) ? 1 : 0.2;
    };

    const canSelectDepartment = (apps: App[]) => {
      return apps.some((app) => isAppConnected(Number(app.id)));
    };

    const selectedDepartments = Object.entries(departmentMap).filter(
      ([departmentId]) => Number(departmentId) === selectedDepartmentId
    );

    const unselectedDepartments = Object.entries(departmentMap).filter(
      ([departmentId]) => Number(departmentId) !== selectedDepartmentId
    );

    useEffect(() => {
      if (selectedDepartments.length === 1 && tableRef.current) {
        tableRef.current.scrollTop = 0;
      }
    }, [selectedDepartments.length]);

    return (
      <div
        className="table-responsive"
        style={{ height: "500px", overflowY: "auto" }}
        ref={tableRef}
      >
        {selectedDepartments.map(
          ([departmentId, { name, description, apps }]) => (
            <div
              key={departmentId}
              className="d-flex align-items-center justify-content-between position-relative mb-4 p-3 rounded border-primary border-3 border-dotted bg-light-primary"
              style={{ width: "100%" }}
            >
              <div className="d-flex flex-column mx-2" style={{ width: "60%" }}>
                <div className="fw-bolder fs-5">{name}</div>
                <div className="fs-7 text-muted">{description}</div>
              </div>
              <div
                className="d-flex flex-wrap justify-content-start symbol-group symbol-hover"
                style={{ maxWidth: "35%", marginLeft: "10px" }}
              >
                {apps.slice(0, 10).map((app, index) => (
                  <div
                    key={app.id}
                    className="symbol symbol-25px symbol-circle bg-body"
                    style={{
                      marginRight: "8px",
                      zIndex: 99 - index,
                      borderRadius: 12.5,
                    }}
                  >
                    <img
                      src={app.logo}
                      alt={app.name}
                      title={app.name}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      style={{
                        opacity: getAppOpacity(Number(app.id)),
                        padding: 3,
                        borderRadius: 15.5,
                      }}
                    />
                  </div>
                ))}

                {apps.length > 10 && (
                  <span
                    className="symbol symbol-30px symbol-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={`+${apps.length - 10} more`}
                  >
                    <span className="symbol-label fs-8">
                      +{apps.length - 10}
                    </span>
                  </span>
                )}
              </div>

              <div
                className="btn btn-sm btn-icon btn-active-color-danger d-flex align-items-center justify-content-center "
                style={{}}
                onClick={() => {
                  onDepartmentToggle(null);
                }}
              >
                <i className="ki-duotone ki-cross-square fs-1">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </div>
            </div>
          )
        )}

        {selectedDepartments.length > 0 && (
          <div className="d-flex justify-content-end align-items-center my-4">
            <hr className="flex-grow-1 border-1 border-gray-300" />
            <span className="px-3 text-muted">
              {selectedDepartments.length} Selected department
            </span>
          </div>
        )}

        {unselectedDepartments.map(
          ([departmentId, { name, description, apps }]) => (
            <div
              key={departmentId}
              className="d-flex align-items-center justify-content-between position-relative mb-4 p-3 rounded border-gray-300 border-dashed"
              onClick={() => {
                if (!canSelectDepartment(apps)) {
                  toast.error(i18n.ToastMessages.avatarDepartmentAppError, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                  ReactGA.event({
                    category: "avatars_page",
                    action: "select_avatar_department",
                    label: "select_avatar_department_button",
                  });
                  return;
                }
                onDepartmentToggle(Number(departmentId));
              }}
              style={{ width: "100%" }}
            >
              <div className="d-flex flex-column mx-2" style={{ width: "50%" }}>
                <div className="fw-bolder fs-5 ">{name}</div>
                <div className="fs-7 text-muted">{description}</div>
              </div>
              <div
                className="d-flex flex-wrap justify-content-start symbol-group symbol-hover"
                style={{ maxWidth: "50%" }}
              >
                {apps
                  .sort(
                    (a, b) =>
                      Number(isAppConnected(b.id)) -
                      Number(isAppConnected(a.id))
                  )
                  .slice(0, 10)
                  .map((app, index) => (
                    <div
                      key={app.id}
                      className="symbol symbol-25px symbol-circle bg-body"
                      style={{
                        marginRight: "8px",
                        zIndex: 99 - index,
                        borderRadius: 12.5,
                      }}
                    >
                      <img
                        src={app.logo}
                        alt={app.name}
                        title={app.name}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        style={{
                          opacity: getAppOpacity(Number(app.id)),
                          padding: 3,
                          borderRadius: 15.5,
                        }}
                      />
                    </div>
                  ))}

                {apps.length > 10 && (
                  <span
                    className="symbol symbol-30px symbol-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={`+${apps.length - 10} more`}
                  >
                    <span className="symbol-label fs-8">
                      +{apps.length - 10}
                    </span>
                  </span>
                )}
              </div>
            </div>
          )
        )}
      </div>
    );
  });

export default DepartmentSelectionTable;
