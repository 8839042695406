import React, { useEffect, useState, useCallback } from "react";
import { Navigate } from "react-router-dom";
import { checkSessionStatus } from "../../../../helpers/api";
import stores from "../../../stores";
import { Company } from "../../../models/Company";
import { KTSVG } from "../../../../helpers";

type SessionStatus = {
  status: string;
  session?: {
    plan: {
      id: string;
    };
  };
};

const SuccessPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [redirectLoading, setRedirectLoading] = useState(false);

  const checkSessionWithRetry = useCallback(
    async (
      sessionId: string,
      retries = 3,
      delay = 2000
    ): Promise<SessionStatus | null> => {
      try {
        const sessionStatus = await checkSessionStatus(sessionId);
        if (sessionStatus.status === "paid") {
          return sessionStatus;
        } else if (retries > 0) {
          await new Promise((resolve) => setTimeout(resolve, delay));
          return checkSessionWithRetry(sessionId, retries - 1);
        }
        return sessionStatus;
      } catch (error) {
        console.error("Error checking session status:", error);
        return null;
      }
    },
    []
  );

  useEffect(() => {
    const handleReturnFromStripe = async () => {
      const sessionId = sessionStorage.getItem("stripeSessionId");

      if (sessionId) {
        try {
          const sessionStatus = await checkSessionWithRetry(sessionId);
          const selectedCompany = stores.companyStore.selectedUserCompany;

          if (selectedCompany) {
            if (
              sessionStatus &&
              sessionStatus.status === "paid" &&
              sessionStatus.session?.plan?.id
            ) {
              const updatedCompany: Company = {
                ...selectedCompany,
                plan: "Premium",
                is_index_created: selectedCompany.is_index_created,
                logo: selectedCompany.logo || "",
                index_updated_at: selectedCompany.index_updated_at,
                available_avatar_count: 1,
              };

              const success = await stores.companyStore.updateCompany(
                updatedCompany
              );

              if (success) {
                stores.userStore.currentUser.is_onboarded = true;
                await stores.userStore.updateUserData();
                setSuccess(true);

                sessionStorage.setItem("paymentSuccess", "true");
                sessionStorage.removeItem("stripeSessionId");
              } else {
                console.error("Failed to update company plan to Premium.");
              }
            } else {
              console.warn("Payment failed or session plan ID is missing.");
              const updatedCompany: Company = {
                ...selectedCompany,
                plan: "Free",
                is_index_created: selectedCompany.is_index_created,
                logo: selectedCompany.logo || "",
                index_updated_at: selectedCompany.index_updated_at,
              };

              const success = await stores.companyStore.updateCompany(
                updatedCompany
              );
              if (success) {
                stores.userStore.currentUser.is_onboarded = true;
                await stores.userStore.updateUserData();
              } else {
                console.error("Failed to update company to Free plan.");
              }
            }
          } else {
            console.error("No selected company found.");
          }

          sessionStorage.removeItem("stripeSessionId");
        } catch (error) {
          console.error("Error during payment session handling:", error);
        }
      }
      setLoading(false);
    };

    handleReturnFromStripe();
  }, [checkSessionWithRetry]);

  const handleRedirect = useCallback(async () => {
    setRedirectLoading(true);

    // Retry mechanism for checking subscription status
    const checkSubscriptionWithRetry = async (
      retries = 20,
      delay = 2000
    ): Promise<boolean> => {
      try {
        const email = stores.userStore.currentUser?.email;

        if (!email) {
          console.error("Email is undefined, cannot fetch subscription.");
          return false;
        }

        await stores.userStore.getStripeUser(email);

        // Check if the subscription is active
        if (stores.userStore.currentUser?.subscription?.status === "active") {
          return true;
        } else if (retries > 0) {
          await new Promise((resolve) => setTimeout(resolve, delay));
          return checkSubscriptionWithRetry(retries - 1, delay);
        }
        console.warn("Max retries reached. No active subscription found.");
        return false;
      } catch (error) {
        console.error("Error checking subscription status:", error);
        return false;
      }
    };

    const hasSubscription = await checkSubscriptionWithRetry();

    if (hasSubscription) {
      window.location.href = "/chat";
    } else {
      console.warn(
        "User does not have an active subscription. No redirection."
      );
    }

    setRedirectLoading(false);
  }, []);

  if (!stores.companyStore.selectedUserCompany) {
    console.warn("No company found, redirecting to home.");
    return <Navigate to="/" />;
  }

  if (loading || redirectLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-center flex-column-fluid">
        <div className="w-lg-600px p-10 p-lg-15 mx-auto">
          <div className="text-center mb-10">
            {success ? (
              <>
                <h1 className="text-dark mb-3">Payment Successful!</h1>
                <div className="text-gray-400 fw-bold fs-5 mb-10">
                  Your payment was completed successfully.
                </div>
                <KTSVG
                  path="/media/icons/duotune/general/gen026.svg"
                  className="svg-icon svg-icon-1 svg-icon-primary"
                />
              </>
            ) : (
              <>
                <h1 className="text-dark mb-3">Payment Failed!</h1>
                <div className="text-gray-400 fw-bold fs-5 mb-10">
                  Unfortunately, your payment could not be completed.
                </div>

                <KTSVG
                  path="/media/icons/duotune/general/gen040.svg"
                  className="svg-icon svg-icon-1 svg-icon-danger"
                />
              </>
            )}
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleRedirect}
            >
              Go to CompanyDNA
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
