import React, { useState, useEffect } from "react";
import { KTIcon } from "../../../../helpers";
import stores from "../../../stores";
import { uploadFile } from "../../../../helpers/api/fileHelper";
import { createCheckoutSession } from "../../../../helpers/api";

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "";

interface SummaryStepProps {
  onFinish: () => void;
  onBack: () => void;
  formData: any;
  createCompany?: () => Promise<void>;
}

const isTestMode = stripeKey.includes("test");

const SummaryStep: React.FC<SummaryStepProps> = ({
  onFinish,
  onBack,
  formData,
}) => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const billingType: "monthly" | "annual" = formData.billingType;

  const plans = [
    {
      title: "Free",
      subTitle: "Essential Features for Small Teams",
      description:
        "Access up to 3 applications, manage 3 employees, and connect 1 admin. Includes basic storage and LLM connection. A great plan to start with essential features for small teams and startups.",
      priceMonth: "0$",
      priceAnnual: "0$",
      priceIdMonth: "",
      priceIdAnnual: "",
      paymentLinks: {
        month: "#",
        annual: "#",
      },
      default: true,
      custom: false,
      tokenInfo: "25K token limit",
      features: [
        { title: "Access to 3 applications", supported: true },
        { title: "Add up to 3 employees", supported: true },
        { title: "1 LLM connection", supported: true },
        { title: "1 storage connection", supported: true },
        { title: "The Chief", supported: false },
        { title: "1 Admin", supported: true },
        { title: "Auto Sync", supported: false },
        { title: "VoiceAsk", supported: false },
        { title: "Avatars", supported: false },
        { title: "Custom LLM tuning", supported: false },
      ],
    },
    {
      title: "Premium",
      subTitle: "Advanced Features for Growing Teams",
      description:
        "Enjoy unlimited applications, employees, LLMs, and storage connections. Includes premium tools like Auto Sync and VoiceAsk. Perfect for growing teams who need more flexibility and advanced functionality.",
      priceMonth: "49$",
      priceAnnual: "490$",
      isPopular: true,
      priceIdMonth: isTestMode
        ? "price_1Q7cyOBEosKxgEvXrVGCEYuQ"
        : "price_1PYNQ0BEosKxgEvX1NEPABFm",
      priceIdAnnual: isTestMode
        ? "price_1Q7cz6BEosKxgEvXClpeCtqL"
        : "price_1PYNQvBEosKxgEvXJ0zuqCnN",
      paymentLinks: {
        month: "#",
        annual: "#",
      },
      default: false,
      custom: false,
      tokenInfo: "",
      features: [
        { title: "Unlimited access to applications", supported: true },
        { title: "Unlimited employee additions", supported: true },
        { title: "Unlimited LLM connections", supported: true },
        { title: "Unlimited storage connection", supported: true },
        { title: "1 Admin", supported: true },
        { title: "VoiceAsk", supported: true },
        { title: "1 Avatar", supported: true },

        { title: "Auto Sync", supported: true },
        { title: "Custom LLM tuning", supported: false },
        { title: "The Chief", supported: false },
        { title: "Use API", supported: false },
      ],
    },
    {
      title: "Enterprise",
      subTitle: "Comprehensive Solutions for Large Enterprises",
      description:
        "Full access to all advanced features, including unlimited admins, storage, and employees. Get specialized services like on-premise deployment, custom LLMs, and more. Ideal for large organizations with specific needs.",
      default: false,
      custom: true,
      priceId: isTestMode
        ? "price_1PqZumBEosKxgEvXihK0LN6z"
        : "price_1PqweqwosKxgEmvXihK0LN6z",
      tokenInfo: "",
      features: [
        { title: "Unlimited access to applications", supported: true },
        { title: "Unlimited employee additions", supported: true },
        { title: "Unlimited LLM connections", supported: true },
        { title: "Unlimited storage connections", supported: true },
        { title: "Unlimited admin additions", supported: true },
        { title: "VoiceAsk", supported: true },
        { title: "Avatars", supported: true },
        { title: "Auto Sync", supported: true },

        { title: "Custom LLM tuning", supported: true },
        { title: "The Chief", supported: true },
        { title: "Use API", supported: true },
      ],
    },
  ];

  useEffect(() => {
    if (!formData || !formData.name || !formData.selectedPlan) {
      setError("Missing required form data. Please fill out all fields.");
    }
    console.log(
      `Selected Plan: ${formData.selectedPlan}, PriceId: ${formData.priceId}`
    );
  }, [formData]);

  const handleCheckoutSession = async () => {
    try {
      const selectedPlan = plans.find(
        (plan) => plan.title === formData.selectedPlan
      );
      const priceId =
        billingType === "monthly"
          ? selectedPlan?.priceIdMonth
          : selectedPlan?.priceIdAnnual;

      if (!priceId) {
        throw new Error("Price ID not found for the selected plan.");
      }

      const currentSubscriptionId = stores.userStore.currentUser.subscription
        ? stores.userStore.currentUser.subscription.id
        : null;

      const stripeSession = await createCheckoutSession(
        priceId,
        `${process.env.REACT_APP_PUBLIC_URL}/success`,
        currentSubscriptionId
      );

      sessionStorage.setItem("stripeSessionId", stripeSession.id);
      window.location.href = stripeSession.url;
    } catch (error) {
      console.error("Error during Stripe checkout:", error);
      setError("Error during Stripe checkout. Please try again.");
    }
  };

  const createCompany = async () => {
    let logoUrl = "";

    if (formData.logo) {
      try {
        setUploading(true);
        const uploadedLogoUrl = await uploadFile(formData.logo);
        setUploading(false);

        logoUrl = uploadedLogoUrl || "";
      } catch (uploadError) {
        setError("Logo upload failed. Proceeding without logo.");
        console.warn("Logo upload error:", uploadError);
      }
    }

    try {
      if (!stores.companyStore.selectedUserCompany) {
        await stores.companyStore.createCompany(
          formData.name,
          logoUrl,
          formData.teamSize,
          formData.industry,
          "Free"
        );
      }
    } catch (error) {
      setError(
        error instanceof Error ? error.message : "An unexpected error occurred."
      );
      setUploading(false);
    }
  };

  const handleFinish = async () => {
    try {
      setUploading(true);

      // Öncelikle şirketi oluşturuyoruz
      await createCompany();

      // Premium plan seçildiyse Stripe checkout oturumunu başlatıyoruz
      if (formData.selectedPlan === "Premium") {
        await handleCheckoutSession();
      } else if (formData.selectedPlan === "Free") {
        window.location.href = `${process.env.REACT_APP_PUBLIC_URL}/chat`;
      } else if (formData.selectedPlan === "Enterprise") {
        window.location.href = "https://www.companydna.ai/waitlist";
      }
    } catch (error) {
      setError("Failed to create company or start checkout session.");
      console.error(error);
    } finally {
      setUploading(false);
    }
  };

  if (!formData) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className="container w-800px"
      style={{ paddingBottom: "120px", paddingTop: "30px" }}
    >
      <div className="card card-flush shadow-sm mb-10">
        <div className="card-header py-5">
          <h3 className="card-title fw-bold text-gray-800 fs-2 mb-0">
            Review Your Information
          </h3>
        </div>

        <div className="card-body py-5">
          <div className="row mb-5">
            <div className="col-md-8">
              <div className="d-flex flex-start align-items-center mb-5">
                <div className="symbol symbol-50px me-5">
                  <span className="symbol-label bg-light">
                    <KTIcon iconName="home" className="fs-2 text-primary" />
                  </span>
                </div>
                <div>
                  <h4 className="text-gray-700 fw-bold mb-1">Company Name</h4>
                  <p className="text-muted fs-6">{formData.name}</p>
                </div>
              </div>

              {formData.companyDescription && (
                <div className="d-flex align-items-center mb-5">
                  <div className="symbol symbol-50px me-5">
                    <span className="symbol-label bg-light">
                      <KTIcon iconName="text" className="fs-2 text-primary" />
                    </span>
                  </div>
                  <div>
                    <h4 className="text-gray-700 fw-bold mb-1">
                      Company Description
                    </h4>
                    <p className="text-muted fs-6">
                      {formData.companyDescription}
                    </p>
                  </div>
                </div>
              )}
              <div className="d-flex align-items-center mb-5">
                <div className="symbol symbol-50px me-5">
                  <span className="symbol-label bg-light">
                    <KTIcon iconName="people" className="fs-2 text-primary" />
                  </span>
                </div>
                <div>
                  <h4 className="text-gray-700 fw-bold mb-1">Team Size</h4>
                  <p className="text-muted fs-6">{formData.teamSize}</p>
                </div>
              </div>
              {formData.industry && (
                <div className="d-flex align-items-center mb-5">
                  <div className="symbol symbol-50px me-5">
                    <span className="symbol-label bg-light">
                      <KTIcon
                        iconName="briefcase"
                        className="fs-2 text-primary"
                      />
                    </span>
                  </div>
                  <div>
                    <h4 className="text-gray-700 fw-bold mb-1">Company Type</h4>
                    <p className="text-muted fs-6">
                      {formData.industry.charAt(0).toUpperCase() +
                        formData.industry.slice(1)}
                    </p>
                  </div>
                </div>
              )}

              {/* Selected Plan */}
              <div className="d-flex align-items-center mb-5">
                <div className="symbol symbol-50px me-5">
                  <span className="symbol-label bg-light">
                    <KTIcon iconName="document" className="fs-2 text-primary" />
                  </span>
                </div>
                <div>
                  <h4 className="text-gray-700 fw-bold mb-1">Selected Plan</h4>
                  <p className="text-muted fs-6 my-0">
                    {formData.selectedPlan}
                  </p>
                  {formData.selectedPlan === "Premium" &&
                    formData.billingType && (
                      <p className="text-muted fs-6 ">
                        Billing Type:{" "}
                        {formData.billingType === "monthly"
                          ? "Monthly"
                          : "Annual"}
                      </p>
                    )}
                </div>
              </div>

              {formData.selectedPlan === "Free" && (
                <div className="alert alert-warning">
                  <strong>Token Limit: </strong> 25K token limit for the Free
                  plan.
                </div>
              )}
            </div>

            <div className="col-md-4 text-center">
              {formData.logo && (
                <div className="d-flex align-items-center justify-content-center mb-5">
                  <div className="symbol symbol-125px me-5">
                    <img
                      src={URL.createObjectURL(formData.logo)}
                      alt="Company Logo"
                      className="img-fluid rounded"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="text-muted fs-6">
            Please review all the details carefully before completing the
            process.
          </div>

          {error && <div className="alert alert-danger mt-4">{error}</div>}
        </div>
      </div>

      <div
        className="fixed-bottom bg-white pb-20 pt-10"
        style={{ height: "80px" }}
      >
        <div className="container d-flex justify-content-between w-50">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onBack}
            disabled={uploading}
          >
            Back
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleFinish}
            disabled={uploading}
          >
            {uploading
              ? "Creating..."
              : formData.selectedPlan === "Enterprise"
              ? "Contact Us"
              : formData.selectedPlan === "Free"
              ? "Create Company"
              : "Proceed to Checkout"}{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SummaryStep;
